import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Box, Checkbox, Typography, withStyles, Dialog, Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import GetAppIcon from '@material-ui/icons/GetApp'
import { ThemeButton, FoldingPanel, ConfirmationDialog } from '../../../../../ui'
import { useCompanyProfileContext } from '../../../contexts'
import { usePublicOfferReducer, usePublicOfferDialogs, usePublicOfferCalls } from '../hooks'
import { ResumePublicOfferDialog } from '../components/dialogs/'
import { publicOfferStyles } from './add-public-offers.styles'
import { REJECTED_APPS } from '../constants/status-po.constants'
import { feedback } from '../../../../../core/feedback'
import { useCombos } from '../../../../combos'
import { PublicOfferViewer } from './add-public-offers-views.component'
import { ModalContext } from '../../../../../ui/modal/contexts'
import { ORIGEN_KEYS } from '../constants'

export const AddPublicOffer = withStyles(publicOfferStyles)(({ classes }) => {
  const { company } = useCompanyProfileContext()
  const { openMultiFile, openConfirmationDialog, openGeneralCondition } = usePublicOfferDialogs()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const { formatMessage } = useIntl()
  const {
    publicOffer,
    publicOfferData,
    publicOffersToSend,
    tooglePublicOffers,
    acceptPublicOffer,
    isLoading,
    resetPublicOffers,
    generalConditionsToogle,
    generalConditions,
    resumeFields,
    generalConditionsDocumentId,
  } = usePublicOfferReducer()
  const { downloadOPFile, cancelOp, addObservaciones,postAutoAddPO } = usePublicOfferCalls()
  const combos = useCombos(['tipo_mercado_adhesiones'])

  const primaryCompany = useSelector((state) => state.global.empresa)
  const havePrimaryFieldsUploaded =
    !!company.iban_op &&
    !!company.nif_responsable_legal &&
    !!company.responsable_legal &&
    !!company.codigo_registro_industrial &&
    !!company.sociedad &&
    !!company.poblacion &&
    !!company.calle &&
    !!company.numero &&
    !!company.cod_codigo_postal &&
    !!primaryCompany.nif_responsable_legal &&
    !!primaryCompany.responsable_legal &&
    !!primaryCompany.codigo_registro_industrial

  const [docsReaded, setDocsReaded] = useState([])
  const [primaryDocsUpdated, setPrimaryDocsUpdated] = useState([])
  const [forceRefresh, setForceRefresh] = useState(false)
  const [generalCheckAvailable, setGeneralCheckAvailable] = useState(generalConditions)
  const [openResume, setOpenResume] = useState(false)

  useEffect(() => {
    if (!company.empresas_filiales && combos.tipo_mercado_adhesiones?.data.length > 0) {
      publicOfferData(company.id, combos)
    }
  }, [forceRefresh, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const supportArray = []
    if (primaryCompany.documento_nif === null) {
      supportArray.push('NIF Empresa')
    }
    if (primaryCompany.registro_industrial === null) {
      supportArray.push('Registro Industrial')
    }
    if (primaryCompany.formulario_rgpd === null) {
      supportArray.push('Formulario RGPD')
    }
    setPrimaryDocsUpdated(supportArray)
  }, [company, primaryCompany])

  const handleSetMultiCheck = (docId) => {
    setDocsReaded([...docsReaded, docId])
  }

  const handleShowFile = (fileId, docId) => {
    openMultiFile(fileId, docId, handleSetMultiCheck)
  }
  const handleOpenAddModal = async () => {
    await openConfirmationDialog(
      publicOffersToSend,
      generalCheckAvailable,
      generalConditions,
      setForceRefresh,
      forceRefresh,
      resetPublicOffers,
    )
  }
  const handleOpenGeneralConditions = () => {
    openGeneralCondition(generalConditionsDocumentId, handleCheckAvailable)
  }

  const handleCheckAvailable = () => {
    setGeneralCheckAvailable(true)
  }

  const handleToogleCondicionesGenerales = () => {
    if (generalCheckAvailable) {
      generalConditionsToogle(!generalConditions)
    } else {
      feedback(
        'warning',
        formatMessage({ id: 'pages.profile.company.guild.publicOffer.notReaded' }),
      )
    }
  }

  const handleCheckPublicOffer = (poId, estadoOp, isFinanciacion) => {
    if (docsReaded.includes(poId) || isFinanciacion) {
      if (REJECTED_APPS[estadoOp]) {
        feedback(
          'warning',
          formatMessage({ id: 'pages.profile.company.guild.publicOffer.rejected' }),
        )
      } else {
        tooglePublicOffers(poId)
      }
    } else {
      feedback(
        'warning',
        formatMessage({ id: 'pages.profile.company.guild.publicOffer.notReaded' }),
      )
    }
  }

  const handleOpenResume = () => {
    setOpenResume(true)
  }

  const handleDownloadFile = (docId) => {
    downloadOPFile(docId)
  }

  const handleAutoJoinConfirmation = (e,po) => {
    e.stopPropagation()
    const callback = async () => {
      const markets = po.value?.split(' | ')
      const response = await postAutoAddPO(markets, generalConditions, publicOfferData)
      if(response) {
        feedback(
          'success',
          formatMessage({
            id: 'pages.profile.company.guild.info.publicOffer.autoJoin.success',
          }) + po.value,
        )
        po.automaticallyJoin = true;

        if (!company.empresas_filiales && combos.tipo_mercado_adhesiones?.data.length > 0) {
          await publicOfferData(company.id, combos)
        }
      }
    }

    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.confirmation.title' }) + po.value,
        text: formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.confirmation.text' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: callback,

      },
      type: 'centered',
      maxWidth: 'md',
    })
  }

  const handleRemoveAutoJoin = (e, po) => {
    e.stopPropagation()
    const callback = async () => {
      const markets = po.value?.split(' | ')
      const response = await postAutoAddPO(markets, generalConditions, publicOfferData, false)
      if(response) {
        feedback(
          'success',
          formatMessage({
            id: 'pages.profile.company.guild.info.publicOffer.autoJoin.remove.success',
          }) + po.value,
        )
        po.automaticallyJoin = false;

        if (!company.empresas_filiales && combos.tipo_mercado_adhesiones?.data.length > 0) {
          await publicOfferData(company.id, combos)
        }
      }
    }

    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.remove.confirmation.title' }) + po.value,
        text: formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.remove.confirmation.text' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: callback,

      },
      type: 'centered',
      maxWidth: 'md',
    })
  }

  const AutomaticallyJoinButton = (po) => {
    return (
      po.automaticallyJoin ? (
        <Tooltip title={formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.remove.button' })}>
          <div>
          <ThemeButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={(e) => handleRemoveAutoJoin(e,po)}
          >
            {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.remove.button' })}
          </ThemeButton>
          </div>
        </Tooltip>
        ) :
        generalConditions ?
        (
          <ThemeButton
            size="small"
            color="primary"
            onClick={(e) => handleAutoJoinConfirmation(e,po)}
          >
            {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.button' })}
          </ThemeButton>
        ) :
          (
            <Tooltip title={formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.fields.warning' })}>
              <div>
                <ThemeButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled={true}
                >
                  {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.autoJoin.button' })}
                </ThemeButton>
              </div>
            </Tooltip>
          )
    )
  }

  return (
    <Box p={2} className={classes.root}>
      <Typography variant="h6" color="primary" paragraph>
        {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.title' })}
      </Typography>
      <Box></Box>
      {publicOffer.length >= 1 && generalConditionsDocumentId ? (
        <Box flexDirection="column" display="flex">
          {!company.certificado_iban_op ? (
            <Alert severity="warning">
              {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.iban.warning' })}
            </Alert>
          ) : null}
          {primaryDocsUpdated.length > 0 ? (
            <Alert severity="warning">
              {formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.documents.warning',
              })}
              <Typography>{primaryDocsUpdated.join(',')}</Typography>
            </Alert>
          ) : null}
          {!havePrimaryFieldsUploaded ? (
            <Alert severity="warning">
              {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.fields.warning' })}
            </Alert>
          ) : null}
          {!generalConditions ? (
            <Alert severity="warning">
              {formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.notAdded.warning',
              })}
            </Alert>
          ) : null}
          {!company?.check_resp_sec_info && (
            <Alert severity="warning">
              {formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.notResponsability.warning',
              })}
            </Alert>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box alignItems="center" display="flex">
              <Typography>
                {formatMessage({ id: 'pages.profile.company.checkbox.po.message' })}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mr={1}>
              <PictureAsPdfIcon
                className={
                  docsReaded.includes(generalConditionsDocumentId) ||
                  generalCheckAvailable ||
                  company.acepta_condiciones_generales_op ||
                  generalConditions
                    ? classes.docCheked
                    : classes.noChecked
                }
                onClick={() => handleOpenGeneralConditions(generalConditionsDocumentId)}
              />
              <Box ml={1}>
                <Typography>{formatMessage({ id: 'global.accept' })}</Typography>
              </Box>
              <Checkbox
                disabled={
                  primaryDocsUpdated.length > 0 ||
                  !company.certificado_iban_op ||
                  !havePrimaryFieldsUploaded ||
                  generalConditions
                }
                onChange={() => handleToogleCondicionesGenerales(generalConditions)}
                checked={generalConditions}
              ></Checkbox>
              <GetAppIcon
                className={classes.download}
                onClick={() => handleDownloadFile(generalConditionsDocumentId)}
              />
            </Box>
          </Box>
          {openResume ? (
            <Dialog open={openResume} maxWidth="xl">
              <ResumePublicOfferDialog
                po={resumeFields}
                publicOfferData={publicOfferData}
                cancelOp={cancelOp}
                addObservaciones={addObservaciones}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                setOpenResume={setOpenResume}
                openResume={openResume}
              />
            </Dialog>
          ) : null}
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            {publicOffer.map((po, i) => (
              <FoldingPanel key={i} isEsxpanded={true} title={po.value} headerComponent={AutomaticallyJoinButton(po)}>
                <PublicOfferViewer
                  publicOffer={po}
                  handleShowFile={handleShowFile}
                  handleCheckPublicOffer={handleCheckPublicOffer}
                  handleDownloadFile={handleDownloadFile}
                  docsReaded={docsReaded}
                  generalCheckAvailable={generalCheckAvailable}
                  publicOffersToSend={publicOffersToSend}
                  generalConditions={generalConditions}
                  company={company}
                  disabled={po.automaticallyJoin}
                />
              </FoldingPanel>
            ))}
          </Box>
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Box mr={2} display="flex">
              <ThemeButton
                size="large"
                color="secondary"
                onClick={handleOpenResume}
                loading={isLoading}
                disabled={resumeFields.length === 0}
              >
                {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.resume' })}
              </ThemeButton>
            </Box>
            <ThemeButton
              size="large"
              color="primary"
              onClick={handleOpenAddModal}
              disabled={publicOffersToSend.length === 0}
              loading={isLoading}
            >
              {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add' })}
            </ThemeButton>
          </Box>
        </Box>
      ) : (
        <Box m={4}>
          <Typography>
            {formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.noOffers' })}
          </Typography>
        </Box>
      )}
    </Box>
  )
})
